<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE PURCHASE ORDER DATA</title>
    <section class="content-header">
      <h1>
        Update Purchase Order Data
        <br />
        <h4>Please edit the purchase order transaction data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Purchase Order Transaction</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Purchase Order Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">PO Number</label>
                  <input
                    type="text"
                    v-model="ponumber"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxpoh"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier</label>
                  <select2
                    :data="suplist"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>
                </div>
                <!--<div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Supplier Code</label>
                    <input
                      type="text"
                      v-model="kodesupplier"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Supplier Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getsupplier()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="namasupplier"
                    autocomplete="off"
                    class="form-control"
                  />
                </div> -->
                <div class="form-group">
                  <label for="exampleInputEmail1">PO Date</label>
                  <input
                    type="date"
                    v-model="podate"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="notespoh"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/po">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
                &nbsp;
                <router-link :to="'/add-po-detail/' + this.ponumber">
                  <button class="btn btn-info"><i class="fa fa-plus"></i> Add Details</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updtdt",
  data() {
    return {
      loading: false,
      ponumber: "",
      idxpoh: "",
      kodesupplier: "",
      namasupplier: "",
      podate: "",
      notespoh: "",
      valuesup: "",
      suplist:[]
    };
  },
  created() {
    // this.loadData();
    this.getSupplierList()
  },
  methods: {
    getsupplier() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getsupplierbykode?kode_supplier=" + this.kodesupplier;
      const urlAPIget =
        this.$apiurl +
        "supplier/getsupplierbykode?kode_supplier=" +
        this.kodesupplier;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Supplier not found",
              showConfirmButton: false
            });
          } else {
            this.namasupplier = resp.data.data.nama_supplier;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSupplierList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.suplist = resp.data.data;
          this.suplist.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
          this.loadData();
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    updatesup(value){
      this.valuesup = value;
    },
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "po_header/getpo_headerbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxpoh = resp.data.data.id;
          this.ponumber = resp.data.data.po_number;
          this.valuesup = resp.data.data.kode_supplier;
          this.podate = resp.data.data.po_date;
          this.notespoh = resp.data.data.notes;
          this.loading = false
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kode = this.ponumber;
      var kodesup = this.valuesup;
      var datepo = this.podate;
      var catatanpo = this.notespoh;
      var idx = this.idxpoh;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "PO Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodesup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier Code / Supplier Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (datepo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "PO Date can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanpo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes PO can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          po_number: this.ponumber,
          kode_supplier: this.valuesup,
          notes: this.notespoh,
          po_date: this.podate,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/po_header/updatepo_header/"+ idx;
        const urlAPIUpdate = this.$apiurl + "po_header/updatepo_header/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "po" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
